
$color-links: #f2cd2c;
$color-highlight: #189995;
$color-highlight-dark: #052834;
$background-dark: rgb(51, 51, 51);
$background-regular: rgb(68, 68, 68);
$background-light: #EAEAEA;
$text-light: white;
$text-dark: rgb(34, 34, 34);

$well-bg: lighten($background-light, 5%);
$well-border: darken($well-bg, 10%);

$brand-primary: $background-dark;
$state-danger-text: red;

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body{
    color: $text-dark;
    background-color: $background-regular;
}

.app-container{
    margin-top:20px;
}

.header{
    background-color:$color-highlight;
    border-radius: $border-radius-base;
    width:100%;
    height: 100px;
    img{
        // background-color: $brand-primary;
        color: $text-light;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        display:block;
    }
}

.success-panel{
    text-align: center;
}

.update-reminder{
    padding: 5px;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    span{
        color: $text-light;
        font-weight: bold;
    }
}

.notifications{
    margin-top: 10px;
    .server-alert{
        display: inherit!important;
    }
    .alert{
        display:none;
    }
}

.related-application-container{
    margin:auto;
    width: 100%;
    text-align: center;

    strong{
        display:block;
        margin-bottom:10px;
    }
}

.application-form{

    textarea[name="additional_comments"]{
        height:150px;
    }

    .file-uploads-container{

        .upload-row{
            margin-bottom: 4px;
        }
    }

    .casting-call-selector{
        width:90%;
    }


    .help-block{
        display: none;
    }
    label span{
        color: $state-danger-text;
    }

    .skill-tile{
        min-height: 350px;

        .checkbox{
            margin-bottom:15px!important;
            margin-left:5px;
        }

        select {
            display: none;
            margin-bottom: 10px!important;
        }
        label{
            margin-bottom: 5px!important;
        }
    }
}